import { NextPage } from 'next';
import { CTAButton, Heading1, Heading2, Stack } from '@ovotech/element';
import Link from 'next/link';
import { ReactNode } from 'react';

interface IErrorComponentProps {
  statusCode?: number;
  children?: ReactNode;
}

const ErrorComponent: NextPage = ({ statusCode }: IErrorComponentProps) => {
  return (
    <Stack className="h-full text-center" spaceBetween={4}>
      <div>
        <Heading1>Oops!</Heading1>
        <Heading2>Looks like we broke something</Heading2>
      </div>

      {statusCode === 404 && (
        <>
          <div>
            Sorry we can't find the page you're looking for. Let's get you back
            to the homepage.
          </div>
          <div>
            <Link href="/">
              <CTAButton variant="primary">Homepage</CTAButton>
            </Link>
          </div>
        </>
      )}
    </Stack>
  );
};

ErrorComponent.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default ErrorComponent;
